import http from "../../../utils/services/http";

export const listPrecatorias = (limit, page, filtros) => {
    let url = `/api/cartas-precatorias?page=${page}&limit=${limit}${filtros}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newPrecatoria = data => {
    let url = '/api/cartas-precatorias'
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updatePrecatoria = (id, data, type = 'PUT') => {
    let url = `/api/cartas-precatorias/${id}`
    if (type === 'PUT') {
        type = http.put
    } else {
        type = http.patch
    }
    return type(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const showPrecatoria = (id) => {
    let url = `/api/cartas-precatorias/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
