export default function (id) {
    let window = typeof id !== 'undefined' ? `precatoria.${id}.show` : 'precatoria.new'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Carta Precatória',
        move: true,
        backdrop: true,
        clickOutside: false,
        closeOnEsc: false,
        windowClass: 'erp-window-modern v2',
        width: '600',
        height: '95%',
        minHeight: '400px',
        minWidth: '500px',
        props: {
            id: id,
            processoId: this.$route.params.id
        }
    }, () => import('./RecursoCartaPrecatoria'))
        .then((wid) => {
            this.$uloc.window.listen(wid, {
                update: (wid, val) => {
                    this.load?.()
                },
                created: (wid, val) => {
                    this.load?.(this.$route.params.id)
                }
            })
        }) // return wid
}
